<template>
  <div>
    <div v-if="loaded && totalTransaction">
      <div class="mb-5">
        <b-button class="mr-2" type="is-primary" :outlined="selection !== 'one_month'"
                  @click="updateData('one_month')">1M
        </b-button>
        <b-button class="mr-2" type="is-primary" :outlined="selection !== 'six_months'"
                  @click="updateData('six_months')">6M
        </b-button>
        <b-button class="mr-2" type="is-primary" :outlined="selection !== 'one_year'"
                  @click="updateData('one_year')">1Y
        </b-button>
        <b-button class="mr-2" type="is-primary" :outlined="selection !== 'ytd'" @click="updateData('ytd')">YTD
        </b-button>
        <b-button class="mr-2" type="is-primary" :outlined="selection !== 'all'" @click="updateData('all')">
          {{ $t('all') }}
        </b-button>
      </div>

      <section class="columns is-tablet pb-3">
        <div class="column is-8-tablet">
          <div class="card">
            <div class="card-content">
              <div class="is-flex is-align-items-center is-justify-content-space-between">
                <p class="is-size-4 has-text-weight-bold">Cash Flow</p>
              </div>

              <div class="is-divider"></div>

              <apexchart type="bar"
                         ref="chart"
                         height="350"
                         :options="barOptions"
                         :series="series"/>

            </div>
          </div>
        </div>
        <div class="column is-4-tablet">
          <div class="card">
            <div class="card-content has-text-centered">


              <div class="is-flex is-justify-content-space-between has-text-success mb-3">
                <div class="is-flex">
                  <b-icon class="mr-2" icon="currency-usd"/>
                  {{ $t('finance.income') }}
                </div>
                <span class="has-text-weight-bold">
                  {{ $currency(totalTransaction.total_pemasukan) }}
                </span>
              </div>

              <div class="is-flex is-justify-content-space-between has-text-danger">
                <div class="is-flex">
                  <b-icon class="mr-2" icon="cart-outline"/>
                  {{ $t('finance.expenses') }}
                </div>
                <span class="has-text-weight-bold">
                  {{ $currency(totalTransaction.total_pengeluaran) }}
                </span>
              </div>

              <div class="is-divider"></div>

              <div class="is-flex is-justify-content-space-between is-align-items-center">
                <p class="has-text-weight-bold">{{ $t('finance.balance') }}</p>
                <span class="is-size-4 has-text-weight-bold">
                {{ $currency(totalTransaction.total_pemasukan - totalTransaction.total_pengeluaran) }}
              </span>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section class="columns is-mobile">
        <div class="column is-half-tablet">
          <div class="card">
            <div class="card-content">
              <div class="is-flex is-justify-content-center has-text-success">
                <b-icon icon="currency-usd" class="mr-2"/>
                <p class="is-size-6 has-text-weight-bold">{{ $t('finance.income') }}</p>
              </div>

              <div class="is-divider mb-0"></div>

              <b-table :mobile-cards="false" :data="incomeDataTable"
                       default-sort-direction="desc"
                       default-sort="nominal">
                <template #empty>
                  <p class="has-text-centered">{{ $t('no') }} Data</p>
                </template>

                <b-table-column field="category_transaction_name" label="Kategori" v-slot="props" sortable>
                  {{ props.row.category_transaction_name }}
                </b-table-column>
                <b-table-column field="nominal" label="Total" v-slot="props" sortable>
                  <span class="has-text-success has-text-weight-bold">{{ $currency(props.row.nominal) }}</span>
                </b-table-column>
              </b-table>
            </div>
          </div>
        </div>
        <div class="column is-half-tablet">
          <div class="card">
            <div class="card-content">
              <div class="is-flex is-justify-content-center has-text-danger">
                <b-icon icon="cart-outline" class="mr-2"/>
                <p class="is-size-6 has-text-weight-bold">{{ $t('finance.expenses') }}</p>
              </div>

              <div class="is-divider mb-0"></div>

              <b-table :mobile-cards="false" :data="expensesDataTable"
                       default-sort-direction="desc"
                       default-sort="nominal">
                <template #empty>
                  <p class="has-text-centered">{{ $t('no') }} Data</p>
                </template>

                <b-table-column field="category_transaction_name" label="Kategori" v-slot="props" sortable>
                  {{ props.row.category_transaction_name }}
                </b-table-column>
                <b-table-column field="nominal" label="Total" v-slot="props" sortable>
                  <span class="has-text-danger has-text-weight-bold">{{ $currency(props.row.nominal) }}</span>
                </b-table-column>
              </b-table>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="has-text-centered my-4" v-else-if="loaded">{{ $t('no') }} Data</div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'FinanceCashFlow',
  components: {
    apexchart: VueApexCharts,
  },
  data: function () {
    return {
      loaded: false,
      selection: 'six_months',
    }
  },
  computed: {
    ...mapState('finance', [
      'selectedAccount',
      'totalTransaction',
      'cashFlow',
    ]),

    ...mapGetters('finance', [
      'incomeTransaction',
      'expensesTransaction'
    ]),

    incomeData() {
      if (this.incomeTransaction) return this.incomeTransaction.map(e => e.nominal)
      return []
    },

    expensesData() {
      if (this.expensesTransaction) return this.expensesTransaction.map(e => e.nominal)
      return []
    },

    incomeDataTable () {
      return this.incomeTransaction || []
    },

    expensesDataTable () {
      return this.expensesTransaction || []
    },

    series() {
      if (this.cashFlow) {
        return [{
          name: this.$t('finance.income'),
          data: this.cashFlow.total_pemasukan ? this.cashFlow.total_pemasukan.reduce((a, b) => {
            let category = this.$dateFormat(b.transaction_date, 'YYYY-MM-DD')
            a[this.barCategories.indexOf(category)] = b.nominal

            return a
          }, []) : [],
        }, {
          name: this.$t('finance.expenses'),
          data: this.cashFlow.total_pengeluaran ? this.cashFlow.total_pengeluaran.reduce((a, b) => {
            let category = this.$dateFormat(b.transaction_date, 'YYYY-MM-DD')

            a[this.barCategories.indexOf(category)] = b.nominal

            return a
          }, []) : [],
        }]
      }
      return []
    },

    barOptions() {
      if (this.cashFlow) {
        return {
          chart: {
            type: 'bar',
            height: 350,
            events: {
              beforeZoom: function (ctx) {
                // we need to clear the range as we only need it on the iniital load.
                ctx.w.config.xaxis.range = undefined
              }
            }
          },
          colors: ["#45BE7D", "#FF4060"],
          plotOptions: {
            bar: {
              columnWidth: '80%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            type: 'datetime',
            categories: this.barCategories,
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: (val) => {
                return this.$currency(val)
              }
            }
          }
        }
      }

      return {}
    },
    barCategories() {
      if (this.cashFlow) {
        let categories = []
        if (this.cashFlow.total_pemasukan) categories = categories.concat(this.cashFlow.total_pemasukan.map(e => e.transaction_date))
        if (this.cashFlow.total_pengeluaran) categories = categories.concat(this.cashFlow.total_pengeluaran.map(e => e.transaction_date))
        categories = categories.sort((a, b) => a > b ? 1 : -1).map(e => this.$dateFormat(e, 'YYYY-MM-DD'))
        return categories.filter(this.$unique)
      }
      return []
    },
  },
  watch: {
    'selectedAccount.account_id'() {
      this.loadData()
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapActions('finance', [
      'getAccount',
      'getTotalTransaction',
      'getGroupTransaction',
      'getCashFlowPerMonth',
    ]),

    loadData() {
      this.$loading()
      let promises = [
        new Promise((resolve, reject) => {
          this.getCashFlowPerMonth().then(res => resolve(res)).catch(err => reject(err))
        }),
        new Promise((resolve, reject) => {
          this.getTotalTransaction().then(res => resolve(res)).catch(err => reject(err))
        }),
        new Promise((resolve, reject) => {
          this.getGroupTransaction({type: 'in'}).then(res => resolve(res)).catch(err => reject(err))
        }),
        new Promise((resolve, reject) => {
          this.getGroupTransaction({type: 'out'}).then(res => resolve(res)).catch(err => reject(err))
        }),
      ]

      Promise.all(promises).finally(() => {
        this.$loading(false)
        this.loaded = true
        this.$nextTick(() => {
          this.updateData(this.selection)
        })
      }).catch(err => {
        console.log(err)
      })
    },
    updateData: function (timeline) {
      this.selection = timeline

      let start_from = null

      switch (timeline) {
        case 'one_month':
          start_from = this.$moment(this.barCategories[1]).subtract(1, 'month').valueOf()
          this.$refs.chart.zoomX(
              start_from,
              this.$timestamp(this.barCategories[1]),
          )
          break
        case 'six_months':
          start_from = this.$moment(this.barCategories[1]).subtract(6, 'month').valueOf()
          this.$refs.chart.zoomX(
              start_from,
              this.$timestamp(this.barCategories[1]),
          )
          break
        case 'one_year':
          start_from = this.$moment(this.barCategories[1]).subtract(1, 'year').valueOf()
          this.$refs.chart.zoomX(
              start_from,
              this.$timestamp(this.barCategories[1]),
          )
          break
        case 'ytd':
          start_from = this.$moment(this.barCategories[1]).startOf('year').valueOf()
          this.$refs.chart.zoomX(
              start_from,
              this.$timestamp(this.barCategories[1]),
          )
          break
        case 'all':
          this.$refs.chart.zoomX(
              this.$timestamp(this.barCategories[0]),
              this.$timestamp(this.barCategories[1]),
          )
          break
        default:
      }
    }
  },
}
</script>
